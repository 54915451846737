<template>
	<article>
		<SearchResultListItem
			:result="result"
			:list-type="CHAIN_LIST"
			:is-mobile="false"
		>
			<template #row3>
				<div
					v-if="result.address"
					class="flex text-sm text-left min-h-9"
				>
					<span class="flex items-start mt-auto">
						<img
							src="@/assets/icons/pin.svg"
							class="inline-block mt-0.5 mr-1"
							width="18"
							height="18"
							alt="location pin icon"
						>
						<span class="leading-5 line-clamp-2">
							{{ address }}
						</span>
					</span>
				</div>
			</template>
		</SearchResultListItem>
	</article>
</template>

<script async>
import SearchResultListItem from '@/components/search/SearchResultListItem.vue'
import { CHAIN_LIST } from '@/constants/search/listTypes.js'
import { formatAddressFromGql } from '@/utils/formatAddress.js'

export default {
	components: {
		SearchResultListItem
	},
	props: {
		result: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			CHAIN_LIST
		}
	},
	computed: {
		address() {
			return formatAddressFromGql(this.result?.address)
		}
	}
}
</script>
